/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios'
import {
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

export enum QueryKeys {
  GetActionRpa = 'GetActionRpa',
  GetFindAllActionRpa = 'GetFindAllActionRpa',
  GetFindAllActionRpaForRestart = 'GetFindAllActionRpaForRestart',
  GetDownloadActionRpa = 'GetDownloadActionRpa',
  GetModelCSV = 'GetModelCSV',
  GetRecipients = 'GetRecipients',
  GetPlans = 'GetPlans',
  GetConfigRpa = 'GetConfigRpa',
}

export interface IQueryOptions extends UseQueryOptions<any, any, any, any> {}
export interface IMutationOptions
  extends UseMutationOptions<any, any, any, any> {}

export interface IFormatResponse<TResponse, TFormatter> {
  formatResponse?: (data: TResponse) => TFormatter
  axios?: AxiosInstance
}

export type TUseQueryTyped<TParams, TResponse> = <TFormatter = TResponse>(
  params: TParams,
  options?: IQueryOptions & IFormatResponse<TResponse, TFormatter>,
) => UseQueryResult<TFormatter, any>

export interface MutationOptions<TData> {
  onSuccess?: (data: TData) => void
  onError?: (message: string) => void
  errorMessage?: string
}
