import { Main } from '../pages/Main';

export const privateRoutes = [
  {
    path: '/oeste-subscription/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-cancellation/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-subscription/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-report/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-cancelamento/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-reativacao/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-atualizacao/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-faturas/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-pagarme-reports/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-novas-assinaturas/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-link-pagamento/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-extract/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-carrinho-abandonado/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-lives/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-atualizacao-fatura/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-atualizacao-cliente/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-conciliacao/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-gcp/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/oeste-vindi-baixa-manual/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/cspesca-omie-conciliacao-tabela-preco/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  },
  {
    path: '/cielo-conciliacao-recebimentos/:rpaId',
    isPrivate: true,
    template: '',
    component: Main,
    subRoutes: []
  }
];
//
