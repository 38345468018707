import { useCallback, useRef, useState, forwardRef } from 'react'

import { IconUploadArchive } from 'assets/IconUploadArchive'
import { cva } from 'class-variance-authority'
import { cn } from 'lib/utils'

import { Progress } from './Progress'

export type DROPZONE_TYPE = 'default' | 'valid' | 'invalid'

interface IDropzoneProps {
  onChange: (value: any) => void
  disabled?: boolean
  progress?: number
}

const dropzoneVariants = cva(
  'w-full min-h-[180px] cursor-pointer pt-8 pb-8 mt-6 mb-8 flex flex-col items-center  border-dashed border-[3px]  rounded-[16px]',
  {
    variants: {
      variant: {
        default: 'border-gray-300',
        valid: 'border-green-300',
        invalid: 'border-red-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

const Dropzone = forwardRef<HTMLInputElement, IDropzoneProps>(
  ({ disabled = false, progress = 0, onChange }, ref) => {
    const [file, setFile] = useState<File>()
    const [dragActive, setDragActive] = useState(false)
    const [dragMessage, setDragMessage] = useState('')
    const [type, setType] = useState<DROPZONE_TYPE>('default')
    const MAX_FILE_SIZE = 50 * 1024 * 1024

    const handleOnChange = (fileParam: any) => {
      setFile(fileParam)
      onChange(fileParam)
    }

    const handleDrag = useCallback(
      (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (disabled) {
          return
        }

        if (event.type === 'dragenter' || event.type === 'dragover') {
          setDragActive(true)
          const file = event.dataTransfer.items[0]
          const isValidType =
            file.type.includes('application/pdf') ||
            file.type.includes('text/csv') ||
            file.type.includes('application/vnd.ms-excel') ||
            file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          const isValid = isValidType

          setDragMessage(
            isValid
              ? 'Solte para fazer o upload do arquivo!'
              : 'Formato de arquivo inválido!',
          )

          setType(isValid ? 'valid' : 'invalid')
        } else if (event.type === 'dragleave') {
          setDragActive(false)
          setDragMessage('')
          setType('default')
        }
      },
      [disabled],
    )

    const handleDrop = (event) => {
      event.preventDefault()
      event.stopPropagation()
      setDragActive(false)

      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const file = event.dataTransfer.files[0]
        const isValidType =
          file.type.includes('application/pdf') ||
          file.type.includes('text/csv') ||
          file.type.includes('application/vnd.ms-excel') ||
          file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        const isValidSize = file.size <= MAX_FILE_SIZE
        const isValid = isValidType && isValidSize
        setType(isValid ? 'valid' : 'invalid')

        if (isValid) {
          handleOnChange(event.dataTransfer.files[0])
          setDragMessage('Arquivo pronto para upload!')
        } else {
          setDragMessage('Formato de arquivo inválido')
        }
      }
    }

    const handleFileSelect = (event) => {
      if (disabled) {
        return
      }

      const file = event.target.files[0]
      const isValidType =
        file.type.includes('application/pdf') || 
        file.type.includes('text/csv') ||
        file.type.includes('application/vnd.ms-excel') ||
        file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      const isValidSize = file.size <= MAX_FILE_SIZE
      const isValid = isValidType && isValidSize

      if (isValid) {
        handleOnChange(event.target.files[0])
      }
    }

    return (
      <>
        <input
          type="file"
          className="hidden"
          id="btnFile"
          ref={ref}
          accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleFileSelect}
        />

        {!file ? (
          <label
            htmlFor="btnFile"
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
            className={cn(
              dropzoneVariants({ variant: dragActive ? type : 'default' }),
            )}
          >
            {dragActive ? (
              <div className="flex items-center justify-center">
                <p className="font-medium">{dragMessage}</p>
              </div>
            ) : (
              <>
                <IconUploadArchive />
                <p className="font-medium mt-6">
                  Clique para fazer upload ou arraste seu arquivo
                </p>
                <span className="text-gray-700 font-medium text-sm ">
                  Arquivo suportado: CSV, XLS ou XLSX
                </span>
              </>
            )}
          </label>
        ) : (
          <Progress
            onRemove={() => setFile(undefined)}
            fileName={file?.name || ''}
            progress={progress}
          />
        )}
      </>
    )
  },
)

export { Dropzone }
