/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react'

import {
  TextField,
  ButtonPortico,
  Modal,
  If,
  toast,
} from '@intraversa-lab/styleguide'
import {Box, CircularProgress} from '@mui/material'
import {
  EMAIL_TYPE,
  ENUM_EMAIL_TYPE,
  ENUM_TYPES_SCHEDULER_CONFIG,
  ISchedulerConfigBase,
} from 'components/ContainerCollapse/meta/schedulerConfigTypes'
import {apiUrl} from 'configs'
import {IRpaConfigResponse} from 'domains/RpaConfig/rpaConfigTypes'
import {useParams, useSearchParams} from 'react-router-dom'

import * as SContainerCollapse from '../../styles'

import * as S from './styles'

interface IModalEditCredentialsProps {
  isOpen: boolean
  onClose: () => void
  data?: IRpaConfigResponse['config']['outbounds'][0]['credential']
  configSelected: ISchedulerConfigBase
  typeEmail?: ENUM_EMAIL_TYPE
}

export const ModalEditCredentials: React.FC<IModalEditCredentialsProps> = ({
  configSelected,
  data,
  isOpen,
  onClose,
  typeEmail,
}) => {
  const {rpaId} = useParams()
  const [showSavingCredentials, setSavingCredentials] = useState(false)
  const [showLoading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const domain = searchParams.get('domain')
  const memberId = searchParams.get('member_id')
  const scope = searchParams.get('scope')
  const serverDomain = searchParams.get('server_domain')

  const [formData, setFormData] = React.useState<any>((data?.data as any) || {})

  const handleInputChange = (name: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSave = async () => {
    try {
      data.data = formData

      setLoading(true)
      setSavingCredentials(true)

      const response = await fetch(
        `${apiUrl.url}/rpa/scheduler/rpa-config-credential/${rpaId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
          body: JSON.stringify(data),
        },
      )

      if (!response.ok) {
        toast({
          type: 'error',
          text: 'Erro ao salvar credenciais.',
          description: 'Tente novamente em instantes.',
        })
        setLoading(false)
        setSavingCredentials(false)
        throw new Error('Failed to update credentials')
      }

      toast({
        type: 'success',
        text: 'Credenciais salvas com sucesso.',
        description: 'Você pode testar a conexão agora.',
      })
      setLoading(false)
      setSavingCredentials(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <>
      <If
        condition={
          configSelected?.type === ENUM_TYPES_SCHEDULER_CONFIG.OMIE &&
          !showLoading
        }>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          maxWidth={900}
          showCloseButton={false}
          defaultPaddingContent={'64px 40px'}>
          <S.Container>
            <div className="Container--Header">
              <S.Title>
                {typeEmail === 'AUTOMATIC'
                  ? configSelected?.titleModal2
                  : configSelected?.titleModal}
              </S.Title>
              <S.Description>
                {typeEmail === 'AUTOMATIC'
                  ? configSelected?.descriptionModal2
                  : configSelected?.descriptionModal}
              </S.Description>
            </div>

            <S.WrapperInputs>
              {configSelected?.fields.map((item) => (
                <SContainerCollapse.WrapperInput key={item.name}>
                  <SContainerCollapse.TextLg className="labelInput">
                    {item.label}
                  </SContainerCollapse.TextLg>
                  <TextField
                    showIconPassword={item.showIconPassword}
                    type={item.typeInput}
                    defaultValue={(data?.data as any)?.[item.name]}
                    onChange={(e) =>
                      handleInputChange(item.name, e.target.value)
                    }
                  />
                </SContainerCollapse.WrapperInput>
              ))}
            </S.WrapperInputs>

            <S.WrapperButtons>
              <ButtonPortico variant="outlined" onClick={onClose}>
                Cancelar
              </ButtonPortico>
              <ButtonPortico onClick={handleSave}>
                {' '}
                Salvar alterações
              </ButtonPortico>
            </S.WrapperButtons>
          </S.Container>
        </Modal>
      </If>
    </>
  )
}
