import { QueryKeys } from "infra";
import { useQuery } from "react-query";

import { flowService } from "../flowService";

export function useGetRpaFlow({
  page = 0,
  offset = 10,
  sort = "init_flow",
  status = "",
}: {
  page: number;
  offset: number;
  sort?: string;
  status?: string;
}) {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.GetActionRpa, { page, offset, sort, status }],
    queryFn: async () => {
      const response = await flowService.getRpaFlow({
        page,
        offset,
        sort,
        status,
      });
      return response;
    },
  });

  return {
    flows: data,
    isLoading,
    isFetching,
    isError,
    refetch,
  };
}
