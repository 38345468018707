/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';

import { TextField, ButtonPortico, If } from '@intraversa-lab/styleguide';
import { IconEdit, IconReload } from 'components/Icons/Icons';
import { usePostTestConnection } from 'domains/PagarMe/useCases/usePostTestConnection';
import { IRpaConfigResponse } from 'domains/RpaConfig/rpaConfigTypes';

import {
  IModalConnectivityType,
  ModalConnectivity
} from './components/ModalConnectivity';
import { ModalEdit, ModalSchedulerEdit } from './components/ModalEdit';
import { ModalEditCredentials } from './components/ModalEditCredentials';
import {
  ENUM_TYPES_SCHEDULER_CONFIG,
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes,
  ISchedulerConfigBase
} from './meta/schedulerConfigTypes';
import * as S from './styles';

export interface CardIntegrationProps {
  type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES;
  data: IRpaConfigResponse['config']['outbounds'][0]['credential'];
  scheduler?: IRpaConfigResponse['config']['scheduler'];
  configId: string;
}

export const CardIntegration: React.FC<CardIntegrationProps> = ({
  type,
  data,
  scheduler
}) => {
  const isEmail = type === 'EMAIL';
  const [showLabelButton, setShowLabelButton] = useState(false);
  const [showTestingConnectivity, setShowTestingConnectivity] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    onTestConnection,
    isLoading: isLoadingTestConnection,
    isSuccess: isSuccessConnection,
    isError
  } = usePostTestConnection();

  const configSelected = schedulerConfigTypes.find(
    config => config.type === type
  );

  console.log(schedulerConfigTypes);
  console.log(configSelected);

  const typeConnection: IModalConnectivityType = useMemo(() => {
    if (isError) {
      return 'ERROR';
    }
    if (isSuccessConnection) {
      return 'SUCCESS';
    }
    return 'PENDING';
  }, [isSuccessConnection, isLoadingTestConnection, isError]);

  const handleTestConnection = () => {
    setShowTestingConnectivity(true);
    onTestConnection();
  };

  return (
    <S.CollapseContainer>
      <S.Wrapper>
        <S.CollapseHeader>
          <div>
            <S.TextLg>{configSelected?.title}</S.TextLg>
            <S.TextDescription>
              Ultima edição 20/11/2023 09:34:01
            </S.TextDescription>
          </div>

          <S.WrapperButtons>
            {configSelected?.type !== ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER && (
              <ButtonPortico
                style={{ height: '40px !important' }}
                variant="outlined"
                onClick={handleTestConnection}
                startIcon={<IconReload />}
                onMouseEnter={() => setShowLabelButton(true)}
                onMouseLeave={() => setShowLabelButton(false)}
              >
                {showLabelButton && 'Testar Conectividade'}
              </ButtonPortico>
            )}
            <div className="middle-button">
              <ButtonPortico
                startIcon={<IconEdit />}
                variant="outlined"
                onClick={() => setIsEdit(!isEdit)}
                // onClick={() => onOpenModal('CONNECTION')}
              >
                Editar
              </ButtonPortico>
            </div>
          </S.WrapperButtons>
        </S.CollapseHeader>
        {type === ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER && (
          <S.CollapseContent>
            <If condition={!isEmail}>
              {configSelected?.fields.map(item => (
                <S.WrapperInput key={item.name}>
                  <S.TextLg className="labelInput">{item.label}</S.TextLg>
                  <TextField
                    sx={{ paddingLeft: '24px !important' }}
                    type={item.typeInput}
                    showIconPassword={false}
                    defaultValue={scheduler?.[item.name]}
                    disabled
                  />
                </S.WrapperInput>
              ))}
            </If>
          </S.CollapseContent>
        )}
        {type !== ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER && (
          <S.CollapseContent>
            <If condition={!isEmail}>
              {configSelected?.fields.map(item => (
                <S.WrapperInput key={item.name}>
                  <S.TextLg className="labelInput">{item.label}</S.TextLg>
                  <TextField
                    sx={{ paddingLeft: '24px !important' }}
                    type={item.typeInput}
                    showIconPassword={false}
                    defaultValue={(data?.data as any)?.[item.name]}
                    disabled
                  />
                </S.WrapperInput>
              ))}
            </If>
          </S.CollapseContent>
        )}
      </S.Wrapper>

      <ModalConnectivity
        isOpen={showTestingConnectivity}
        onClose={() => setShowTestingConnectivity(false)}
        status={typeConnection as any}
      />
      {type === ENUM_TYPES_SCHEDULER_CONFIG.OMIE && (
        <ModalEditCredentials
          onClose={() => setIsEdit(false)}
          isOpen={isEdit}
          configSelected={configSelected as ISchedulerConfigBase}
        />
      )}
      {type !== ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER && 
       configSelected.type !== ENUM_TYPES_SCHEDULER_CONFIG.OMIE && (
        <ModalEdit onClose={() => setIsEdit(false)} isOpen={isEdit} />
      )}
      {type === ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER && (
        <ModalSchedulerEdit onClose={() => setIsEdit(false)} isOpen={isEdit} />
      )}
    </S.CollapseContainer>
  );
};
