import React, { useEffect, useMemo, useState } from 'react'

import { Button, toast, useConfirmModal } from '@intraversa-lab/styleguide'
import { IconDownload } from 'assets/IconDownload'
import { IconUpdate } from 'assets/IconUpdate'
import { FilterTo } from 'components/FilterTo/FilterTo'
import { Table } from 'components/Table'
import { queryClient } from 'configs/queryClient'
import { useGetDownloadFlow } from 'domains/ActionConfig/useCases/useGetDownloadFlow'
import { IDataFlow, ILiveFlow } from 'domains/Flow/flowTypes'
import { useGetRpaFlow } from 'domains/Flow/useCase/useGetRpaFlow'
import { usePostInit } from 'domains/Flow/useCase/usePostinit'
import { QueryKeys } from 'infra'
import moment from 'moment'
import { UploaderTab } from 'types/UploaderConfig'
import { downloadDocumentNavigator } from 'utils/downlaodCSV'

export const Resumo = ({
  title,
  tabData,
}: {
  title: string
  tabData: UploaderTab
}) => {
  const [tableSelected, setTableSelected] = useState<string[]>([])
  const [filterBy, setFilterBy] = useState<string | null>(null)
  const [paginationProps, setPaginationProps] = useState({
    offset: 10,
    page: 0,
    total: 1,
  })

  const { flows, refetch, isLoading } = useGetRpaFlow({
    page: paginationProps.page,
    offset: paginationProps.offset,
    sort: 'init_flow',
    status: filterBy ?? '',
  })

  const { onDownload, isLoading: isLoadingDownload } = useGetDownloadFlow({
    onSuccess: (data) => {
      console.log('aaaa')
      downloadDocumentNavigator(
        data.base64,
        'Operações',
        true,
        data.extension === 'csv',
      )
    },
  })

  const { renderModal, updateModalStateAndOpenModal } = useConfirmModal()

  const { onInit, isLoading: isLoadingInit } = usePostInit({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa)
    },
  })

  const handleRefetch = () => {
    queryClient.removeQueries(QueryKeys.GetActionRpa)
    refetch()
  }

  const handleDownload = () => {
    onDownload(tableSelected.filter((item) => item !== 'all'))
  }

  function parseStatus(status: string): string {
    switch (status) {
      case 'RUNNING':
        return 'Em andamento'
      case 'STOPPED':
        return 'Parado'
      case 'CANCELED':
        return 'Cancelado'
      case 'FINISHED':
        return 'Finalizado'
      default:
        return 'Status desconhecido'
    }
  }

  const columns = useMemo(() => {
    return tabData.columns?.map((item) => ({
      align: item.align,
      column: item.column,
      label: item.label,
      ...(item.format === 'date' && {
        format: (data) =>
          !!data && data !== null
            ? moment(data).format('DD/MM/YYYY HH:mm:ss')
            : '-',
      }),
    }))
  }, [tabData.columns])

  const handleShowTotal = (flows) => {
    if (flows && flows.body) {
      console.log('Flow Data:', flows.body.total)
      return flows.body.total
    } else {
      return flows.total
    }
  }

  useEffect(() => {
    if (flows) {
      setPaginationProps((old) => ({ ...old, total: handleShowTotal(flows) }))
    }
  }, [flows])

  const handleShowFlowData = (flows) => {
    console.log('Flow Data:', flows)
    if (flows && flows.body) {
      console.log('Flow Data:', flows.body.data)
      return flows.body.data
    } else {
      return flows.data
    }
  }

  return (
    <div
      className="pt-6 pb-6 pl-6 pr-6  ml-6 mr-6 rounded bg-white"
      style={{ border: '1px solid #DFE4E9' }}
    >
      <div className="flex justify-between items-start">
        <div>
          <p className="font-medium">{tabData.title}</p>
          <span className="font-normal text-sm text-gray-700">
            {tabData.description}
          </span>
        </div>

        <div className="flex gap-3 items-center">
          {tabData.actions.run.active && (
            <Button
              variant="contained"
              style={{
                textTransform: 'none',
                fontSize: '16px',
                padding: '12px 24px ',
                height: '44px',
              }}
              disabled={isLoadingInit}
              onClick={() =>
                updateModalStateAndOpenModal({
                  title: `Executar ${title}`,
                  description: `Ao confirmar essa ação você irá executar a geração de um novo relatório.
                   Deseja continuar?`,
                  onConfirm: () => onInit(),
                  maxWidth: 441,
                  type: 'light',
                })
              }
            >
              {tabData.actions.run.label}
            </Button>
          )}

          <button
            className="relative h-11 w-11 px-3 py-3 border border-solid border-gray-300 disabled:opacity-50"
            onClick={handleDownload}
            disabled={isLoadingDownload}
          >
            <IconDownload />

            {!!tableSelected.filter((item) => item !== 'all').length && (
              <div className="bg-[#6D42E1] absolute bottom-[30px] right-[-6px] w-[20px] h-[20px] rounded-full flex items-center justify-center">
                <span className="text-sm text-white">
                  {tableSelected.filter((item) => item !== 'all').length}
                </span>
              </div>
            )}
          </button>

          <button
            className="h-11 w-11 px-3 py-3 border border-solid border-gray-300 disabled:opacity-50"
            onClick={handleRefetch}
            disabled={isLoading}
          >
            <IconUpdate />
          </button>

          <FilterTo
            onChange={({ filterBy }) => setFilterBy(filterBy)}
            options={[
              { label: 'Em andamento', value: 'RUNNING' },
              { label: 'Parado', value: 'STOPPED' },
              { label: 'Cancelado', value: 'CANCELED' },
              { label: 'Finalizado', value: 'FINISHED' },
            ]}
          />
        </div>
      </div>

      <Table
        tableSelected
        typeSelected="checkbox"
        onChange={(value) => setTableSelected(value as string[])}
        value={tableSelected}
        isLoading={isLoading}
        paginable
        paginationProps={paginationProps}
        handleChangePage={(page) =>
          setPaginationProps((old) => ({ ...old, page }))
        }
        handleChangeRowsPerPage={(offset) =>
          setPaginationProps((old) => ({ ...old, offset }))
        }
        columns={columns}
        data={
          flows && Array.isArray(handleShowFlowData(flows))
            ? handleShowFlowData(flows).map((item: IDataFlow) => ({
                name: item.name,
                playlistId: item.playlistId,
                createdAt: item.createdAt,
                renovacoes_totais: item.totalFlowAssets,
                sucesso: item.totalFlowAssetsSuccess,
                error: item.totalFlowAssetsErrors,
                inicio_renovacao: item.initFlow,
                fim_renovacao: item.endFlow,
                status: parseStatus(item.status),
                id: item.id,
              }))
            : []
        }
      />
      {renderModal()}
    </div>
  )
}
