import { useState } from 'react';

import { If } from '@intraversa-lab/styleguide';
import Tab from '@mui/material/Tab';
import TabsMUI from '@mui/material/Tabs';
import { IconClock } from 'assets/IconClock';
import { HeaderMain } from 'components/HeaderMain/HeaderMain';
import moment from 'moment';
import { UploaderConfig } from 'types/UploaderConfig';

import { Assinaturas } from './containers/assinaturas/Assinaturas';
import { Configuracoes } from './containers/configuracoes/Configuracoes';
import { Resumo } from './containers/resumo';

export const Main = ({
  title,
  type,
  data
}: {
  title: string;
  type: string;
  data: UploaderConfig;
}) => {
  const [value, setValue] = useState('resumo');
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="w-screen h-screen bg-grayf9">
      <div className="w-screen pt-6 pl-8 pr-8 mb-8">
        <HeaderMain />
      </div>

      <div className="pl-8 pr-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center ">
            <h1 className="font-semibold text-[32px]">{data.title}</h1>
            <div className="ml-10 pl-3 pr-3 rounded-sm bg-green-100 h-7 flex justify-center items-center">
              <p className="text-[16px] font-normal text-green1 h-6">Ativo</p>
            </div>
          </div>

          <div className="flex items-center gap-4" style={{ color: '#75808A' }}>
            <IconClock />
            <span className="text-[16px] font-medium ">Última atualização</span>
            <span className="font-medium ">
              {moment().format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
        </div>
      </div>

      <div
        className="w-screen mt-4"
        style={{ height: '1px', backgroundColor: '#EFF2F4' }}
      ></div>

      <div className="pl-8 pr-8">
        <div className="flex items-center justify-between">
          <TabsMUI value={value} onChange={handleChange}>
            <Tab value="resumo" label={data.tabs.operation.label} />

            {data.tabs.feature1.active && (
              <Tab
                value="assinaturas"
                label={data.tabs.feature1.label}
                style={{ marginLeft: 32 }}
              />
            )}

            <Tab
              value="configuracoes"
              label="Configurações"
              style={{ marginLeft: 32 }}
            />
          </TabsMUI>
        </div>
      </div>

      <div
        className="w-screen"
        style={{ height: '1px', backgroundColor: '#EFF2F4' }}
      ></div>

      <div className="mt-8">
        <If condition={value === 'resumo'}>
          <Resumo
            title={title?.toLocaleLowerCase() || ''}
            tabData={data.tabs.operation}
          />
        </If>

        <If condition={value === 'assinaturas'}>
          <Assinaturas
            type={type}
            title={title?.toLocaleLowerCase() || ''}
            tabData={data.tabs.feature1}
            // tabData={data.tabs.feature1}
          />
        </If>

        <If condition={value === 'configuracoes'}>
          <Configuracoes />
        </If> 
      </div>
    </div>
  );
};
