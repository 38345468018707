/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'configs'
import { endpointsUploader, endpointsFlow } from 'infra'

import { IResponseFlow } from './flowTypes'

async function getRpaFlow(props: {
  page: number
  offset: number
  sort?: string
  status?: string
}): Promise<IResponseFlow> {
  const response = await api.get(endpointsUploader.getRpaFlow, {
    params: props,
  })
  return response.data
}

async function getRpaFlowForRestart(props: { id: string }): Promise<any> {
  const response = await api.get(endpointsFlow.restartFlow, {
    params: props,
  })
  return response.data
}

async function postInit(): Promise<any> {
  const response = await api.post(endpointsFlow.postInit)
  return response.data
}

export const flowApi = {
  getRpaFlow,
  postInit,
  getRpaFlowForRestart,
}
